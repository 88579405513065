import React, { useState } from "react";

export const CharacterEditScreen = ({ newEntity, setNewEntity, handleAddEntity, toggleInputScreen }) => {
  return (
    <div className="edit-screen-container">
      <h2 className="edit-screen-header">Edit Character</h2>
      <form className="edit-screen-form">
        <input
          type="text"
          value={newEntity.name}
          onChange={(e) => setNewEntity({ ...newEntity, name: e.target.value })}
          placeholder="Enter entity name"
        />
        <input
          type="text"
          value={newEntity.description}
          onChange={(e) => setNewEntity({ ...newEntity, description: e.target.value })}
          placeholder="Enter entity description"
        />
        <input
          type="text"
          value={newEntity.role}
          onChange={(e) => setNewEntity({ ...newEntity, role: e.target.value })}
          placeholder="Enter entity role"
        />
        <input
          type="text"
          value={newEntity.personality}
          onChange={(e) => setNewEntity({ ...newEntity, personality: e.target.value })}
          placeholder="Enter entity personality"
        />
        <input
          type="text"
          value={newEntity.motivation}
          onChange={(e) => setNewEntity({ ...newEntity, motivation: e.target.value })}
          placeholder="Enter entity motivation"
        />
        <input
          type="text"
          value={newEntity.flaw}
          onChange={(e) => setNewEntity({ ...newEntity, flaw: e.target.value })}
          placeholder="Enter entity flaw"
        />
        <input
          type="text"
          value={newEntity.intrigue}
          onChange={(e) => setNewEntity({ ...newEntity, intrigue: e.target.value })}
          placeholder="Enter entity intrigue"
        />
        <input
          type="text"
          value={newEntity.physicalDescription}
          onChange={(e) => setNewEntity({ ...newEntity, physicalDescription: e.target.value })}
          placeholder="Enter entity physical description"
        />
		&nbps
        <button type="button" onClick={handleAddEntity}>Add</button>
		&nbps
        <button type="button" onClick={toggleInputScreen}>Cancel</button>
      </form>
    </div>
  );
};