import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Step 1: Import useNavigate
import './rpg-textbox.css';

export const RPGTextBox = ({ textList, emoteType, route }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate(); // Step 2: Use useNavigate hook

  const handleNext = () => {
    if (currentIndex < textList.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      navigate(route); // Step 3: Use navigate for redirection
    }
  };

  const getEmoteImage = () => {
    switch(emoteType) {
      case 'happy':
        return 'path/to/happy/image.png';
      case 'sad':
        return 'path/to/sad/image.png';
      case 'angry':
        return 'path/to/angry/image.png';
      default:
        return 'path/to/default/image.png';
    }
  };

  return (
    <div className="rpg-textbox">
      {textList[currentIndex]}
      <button onClick={handleNext}>Next</button>
      <img src={getEmoteImage()} alt="img" className="emote-image" />
    </div>
  );
};