import React, { useState } from 'react';

export const InputGenerate = ({piece, attribute}) => {





    return (
        <div className="">
            <input
            type="text"
            value={piece[attribute]}
            />
            {attribute}
            <button className='btn'>Generate </button>
        </div>
    );
}