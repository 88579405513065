// apiService.js
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_AZURE_API_URL;

const apiService = {
  get: async (endpoint, data) => {
    try {
      const response = await axios.get(endpoint, { params: data });
      return response.data;
    } catch (error) {
      console.error('Error occurred while fetching data', error);
      throw error;
    }
  },

  post: async (endpoint, data) => {
    try {
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error('Error occurred while posting data', error);
      throw error;
    }
  },

  // Add other methods like PUT, DELETE etc. as needed
};

export default apiService;