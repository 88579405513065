import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // useNavigate instead of useHistory
import { PageLayout } from "./page-layout";
import { useAuth0 } from "@auth0/auth0-react";
import { RPGTextBox } from '../pages/inputs/rpg-textbox'; // Import the RPGTextBox component
import { InputGenerate } from "../pages/inputs/input-generate";

export const EditScreen = ({piece}) => {
  const { user, isLoading } = useAuth0();
  const navigate = useNavigate(); 

  const handleSave = () => {
    //save
    navigate("/main"); 
  };

  if (isLoading) {
    return <div>Loading...</div>; 
  }


  return (
    <PageLayout>
      <div className="container-fluid gma-wrapper">

        {/* <ImageGenerate piece={piece} type="portrait"></ImageGenerate> */}

        <InputGenerate piece={piece} attribute="name" />
        <InputGenerate piece={piece} attribute="description" />
        <InputGenerate piece={piece} attribute="strengths" />
         ...

          
{/* 
         Add reference pieces (coming soon) */}


        <button className="btn">Save</button>
        <button className="btn">Cancel</button>

      </div>
    </PageLayout>
  );
};