// src/components/ImageBox.js
import React from 'react';

const ImageBox = ({ imageUrl }) => {
    const imageBoxStyle = {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100px', // Adjust width as needed
        height: '100px', // Adjust height as needed
        border: '1px solid #ccc',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    };

    return <div style={imageBoxStyle}></div>;
};

export default ImageBox;