import React, { useState } from 'react';

export const NavBar = () => {
  // State to manage which menu is open
  const [openMenu, setOpenMenu] = useState('');

  // Function to handle menu clicks
  const toggleMenu = (menuName) => {
    if (openMenu === menuName) {
      setOpenMenu('');
    } else {
      setOpenMenu(menuName);
    }
  };

  return (
    <nav className="navbar">
      <div className="dropdown">
        <button className="dropdown-button" onClick={() => toggleMenu('file')}>File</button>
        <div className={`dropdown-content ${openMenu === 'file' ? 'dropdown-content-show' : ''}`}>
          <a href="#" className="link">New</a>
          <a href="#" className="link">Open</a>
          <a href="#" className="link">Save</a>
        </div>
      </div>
      <div className="dropdown">
        <button className="dropdown-button" onClick={() => toggleMenu('insert')}>Insert</button>
        <div className={`dropdown-content ${openMenu === 'insert' ? 'dropdown-content-show' : ''}`}>
          <a href="#" className="link">Image</a>
          <a href="#" className="link">Link</a>
          <a href="#" className="link">Table</a>
        </div>
      </div>
      <div className="dropdown">
        <button className="dropdown-button" onClick={() => toggleMenu('about')}>About</button>
        <div className={`dropdown-content ${openMenu === 'about' ? 'dropdown-content-show' : ''}`}>
          <a href="#" className="link">Company</a>
          <a href="#" className="link">Team</a>
          <a href="#" className="link">Contact</a>
        </div>
      </div>
    </nav>
  );
};